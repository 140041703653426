@import url('https://fonts.googleapis.com/css?family=Inter:wght@400;500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: #e7f6ff;
}

* {
  font-family: 'Inter', sans-serif;
}
.wrapper {
  background: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  max-width: 1080px;
  margin: 50px auto;
}

header {
  border-bottom: 1px solid #eaeaea;
  img {
    margin-right: 15px;
  }

  h3,
  p {
    margin: 0;
  }
}

.content {
  h1 {
    margin: 0;
  }
}

.cartEmpty {
  text-align: center;

  p {
    width: 280px;
    line-height: 24px;
  }

  .greenButton {
    width: 245px;
    margin-top: 20px;

    &:hover {
      img {
        transform: rotate(180deg) translateX(3px);
      }
    }

    img {
      position: relative;
      top: 1px;
      transform: rotate(180deg);
      margin-right: 15px;
      transition: transform 0.15s ease-in-out;
    }
  }
}

.search-block {
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  padding: 0 15px;
  position: relative;

  .clear {
    position: absolute;
    right: 0;
    width: 18px;
    height: 18px;
    top: 14px;
    right: 15px;
  }
  input {
    border: 0;
    padding: 13px;
    font-size: 16px;
    width: 200px;
  }
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  z-index: 1;
}

.drawer {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 420px;
  right: 0;
  height: 100%;
  background: #fff;
  box-shadow: -10px 4px 24px rgba(0, 0, 0, 0.1);
  padding: 30px;

  .items {
    flex: 1;
    overflow: auto;
    margin-bottom: 40px;
  }
  h2 {
    margin: 0;
  }
}

.cartTotalBlock {
  ul {
    display: block;
    margin-bottom: 40px !important;

    li {
      display: flex;
      align-items: flex-end;
      margin-bottom: 20px;

      div {
        flex: 1;
        height: 1px;
        border-bottom: 1px dashed #dfdfdf;
        position: relative;
        top: -4px;
        margin: 0 7px;
      }
    }
  }

  .greenButton {
    position: relative;
    &:disabled {
      animation: button-loading 1s ease-in-out infinite;
    }
    &:hover {
      img {
        transform: translateX(5px);
      }
    }
    img {
      position: absolute;
      right: 40px;
      top: 20px;
      transition: transform 0.15s ease-in-out;
    }
  }
}

.greenButton {
  width: 100%;
  height: 55px;
  background: #9dd558;
  border-radius: 18px;
  border: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin: 0 7px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:disabled {
    background-color: #bebebe;
    cursor: default;
  }

  &:hover {
    background: lighten(#9dd558, 5%);
  }

  &:active {
    background: darken(#9dd558, 5%);
  }
}
.cartItem {
  border: 1px solid #f3f3f3;
  border-radius: 20px;
  overflow: hidden;
  padding: 20px;

  .cartItemImg {
    height: 70px;
    width: 70px;
    background-size: contain;
    background-position: 0 -3px;
    background-repeat: no-repeat;
    margin-right: 20px;
  }
  p {
    font-size: 16px;
    margin: 0;
  }

  b {
    font-size: 14px;
  }

  .removeBtn {
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.15s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
}

@keyframes button-loading {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}
