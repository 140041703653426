.card {
  border: 1px solid #f3f3f3;
  padding: 30px;
  width: 220px;
  border-radius: 40px;
  margin-right: 30px;
  margin-bottom: 30px;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
  cursor: pointer;

  .plus {
    cursor: pointer;
  }

  &:hover {
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.06);
    transform: translateY(-5px);
  }

  .favorite {
    position: absolute;
    cursor: pointer;
  }

  span {
    font-size: 13px;
    text-transform: uppercase;
    opacity: 0.5;
  }

  b {
    font-size: 14px;
  }

  h5 {
    font-weight: 400;
    font-size: 14px;
  }
}
